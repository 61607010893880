import myWork1 from '../img/my-work/my-work-001.jpg'
import myWork2 from '../img/my-work/my-work-002.jpg'
import myWork3 from '../img/my-work/my-work-003.jpg'
import myWork4 from '../img/my-work/my-work-004.jpg'
import myWork5 from '../img/my-work/my-work-005.jpg'
import myWork6 from '../img/my-work/my-work-006.jpg'
import myWork7 from '../img/my-work/my-work-007.jpg'
import myWork8 from '../img/my-work/my-work-008.jpg'
import myWork9 from '../img/my-work/my-work-009.jpg'
import myWork10 from '../img/my-work/my-work-010.jpg'
import myWork11 from '../img/my-work/my-work-011.jpg'
import myWork12 from '../img/my-work/my-work-012.jpg'
import myWork13 from '../img/my-work/my-work-013.jpg'
import myWork14 from '../img/my-work/my-work-014.jpg'
import myWork15 from '../img/my-work/my-work-015.jpg'
import myWork16 from '../img/my-work/my-work-016.jpg'
import myWork17 from '../img/my-work/my-work-017.jpg'
import myWork18 from '../img/my-work/my-work-018.jpg'
import myWork19 from '../img/my-work/my-work-019.jpg'
import myWork20 from '../img/my-work/my-work-020.jpg'
import myWork21 from '../img/my-work/my-work-021.jpg'
import myWork22 from '../img/my-work/my-work-022.jpg'
import myWork23 from '../img/my-work/my-work-023.jpg'
import myWork24 from '../img/my-work/my-work-024.jpg'
import myWork25 from '../img/my-work/my-work-025.jpg'
import myWork26 from '../img/my-work/my-work-026.jpg'
import myWork27 from '../img/my-work/my-work-027.jpg'
import myWork28 from '../img/my-work/my-work-028.jpg'
import myWork29 from '../img/my-work/my-work-029.jpg'
import myWork30 from '../img/my-work/my-work-030.jpg'
import myWork31 from '../img/my-work/my-work-031.jpg'
import myWork32 from '../img/my-work/my-work-032.jpg'
import myWork33 from '../img/my-work/my-work-033.jpg'

export const myWorkData = [
    {
        id: 32,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork32
    },
    {
        id: 33,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork33
    },
    {
        id: 1,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork1
    },
    {
        id: 2,
        name: "secondImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork2
    },
    {
        id: 3,
        name: "thirdImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork3
    },
    {
        id: 4,
        name: "fourthImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork4
    },
    {
        id: 5,
        name: "fifthImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork5
    },
    {
        id: 6,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork6
    },
    {
        id: 7,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork7
    },
    {
        id: 8,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork8
    },
    {
        id: 9,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork9
    },
    {
        id: 10,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork10
    },
    {
        id: 11,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork11
    },
    {
        id: 12,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork12
    },
    {
        id: 13,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork13
    },
    {
        id: 14,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork14
    },
    {
        id: 15,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork15
    },
    {
        id: 16,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork16
    },
    {
        id: 17,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork17
    },
    {
        id: 18,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork18
    },
    {
        id: 19,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork19
    },
    {
        id: 20,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork20
    },
    {
        id: 21,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork21
    },
    {
        id: 22,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork22
    },
    {
        id: 23,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork23
    },
    {
        id: 24,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork24
    },
    {
        id: 25,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork25
    },
    {
        id: 26,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork26
    },
    {
        id: 27,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork27
    },
    {
        id: 28,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork28
    },
    {
        id: 29,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork29
    },
    {
        id: 30,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork30
    },
    {
        id: 31,
        name: "firstImg",
        artistName: "Artist Name Here",
        description: "Description of Img",
        img: myWork31
    },
]

export default myWorkData;